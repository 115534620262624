import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import * as _ from 'lodash';
import * as React from 'react';
import { requiredString, validate, ValidationField } from '../../../validators';
import FAT from '../../FontAwesomeWithTooltip';

function getMyDescriptionKey(resourceId: string) {
  return `my-description-${resourceId}`;
}

interface DescriptionComponentProps {
  resourceId?: string;
  label: string;
  placeholder: string;
  onDescriptionChange: (d: string) => void;
  onValidityChange: (v: boolean) => void;
  onDescriptionPublicChange: (p: boolean) => void;
}

interface DescriptionProps extends DescriptionComponentProps {}

interface DescriptionState {
  userHasTouchedForm: boolean;
  description: ValidationField<string>;
  descriptionPublic: boolean;
  rememberDescription: boolean;
}

export default class DescriptionField extends React.Component<DescriptionProps, DescriptionState> {
  constructor(props: DescriptionProps) {
    super(props);

    this.state = {
      description: { value: '', validationResult: { valid: false } },
      descriptionPublic: false,
      userHasTouchedForm: false,
      rememberDescription: false,
    };

    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.handleDescriptionPublicChange = this.handleDescriptionPublicChange.bind(this);
    this.handleRememberDescriptionChange = this.handleRememberDescriptionChange.bind(this);
  }

  componentDidMount() {
    this.setInitialState();
    this.communicateChangesIfNeeded();
  }

  componentDidUpdate(prevProps: DescriptionProps, prevState: DescriptionState) {
    if (!_.isEqual(prevProps, this.props)) {
      if (!this.state.userHasTouchedForm) {
        this.setInitialState();
      }
    }
    if (!_.isEqual(prevState, this.state)) {
      this.communicateChangesIfNeeded();
    }
  }

  setInitialState() {
    let description = '';
    let rememberDescription = false;
    if (this.props.resourceId) {
      const savedDescription = localStorage.getItem(getMyDescriptionKey(this.props.resourceId));
      if (savedDescription) {
        description = savedDescription;
        rememberDescription = true;
      }
    }
    this.setState({
      userHasTouchedForm: false,
      description: this.createDescriptionValidated(description),
      descriptionPublic: true,
      rememberDescription,
    });
  }

  isValid() {
    return this.state.description.validationResult.valid;
  }

  communicateChangesIfNeeded() {
    this.props.onDescriptionChange(this.state.description.value);
    this.props.onDescriptionPublicChange(this.state.descriptionPublic);
    this.props.onValidityChange(this.isValid());
  }

  communicateUserTouchedIfNeeded() {
    if (!this.state.userHasTouchedForm) {
      this.setState({ userHasTouchedForm: true });
    }
  }

  createDescriptionValidated(description: string) {
    return {
      value: description,
      validationResult: validate(description, [requiredString]),
    };
  }

  handleDescriptionChange(description: string) {
    const validated = this.createDescriptionValidated(description);
    this.setState({
      description: validated,
    });
    if (this.props.resourceId && this.state.rememberDescription) {
      const trimmedDescription = description.trim();
      if (trimmedDescription === '' || !validated.validationResult.valid) {
        localStorage.removeItem(getMyDescriptionKey(this.props.resourceId));
      } else if (validated.validationResult.valid) {
        localStorage.setItem(getMyDescriptionKey(this.props.resourceId), trimmedDescription);
      }
    }
    this.communicateChangesIfNeeded();
    this.communicateUserTouchedIfNeeded();
  }

  handleDescriptionPublicChange(checked: boolean) {
    this.setState({ descriptionPublic: checked });
    this.communicateChangesIfNeeded();
    this.communicateUserTouchedIfNeeded();
  }

  handleRememberDescriptionChange(checked: boolean) {
    this.setState({ rememberDescription: checked });
    if (this.props.resourceId) {
      if (checked && this.state.description.value) {
        localStorage.setItem(
          getMyDescriptionKey(this.props.resourceId),
          this.state.description.value
        );
      } else if (!checked) {
        localStorage.removeItem(getMyDescriptionKey(this.props.resourceId));
      }
    }
    this.communicateUserTouchedIfNeeded();
  }

  render() {
    const { description } = this.state;
    const descriptionInputClasses = ['form-control'];

    if (this.state.description.validationResult.valid) {
      descriptionInputClasses.push('is-valid');
    } else {
      descriptionInputClasses.push('is-invalid');
    }

    return (
      <>
        <div className="v-reservation-description-container">
          <label htmlFor="reservation-description">{this.props.label}</label>
          <input
            autoFocus={true}
            tabIndex={4}
            type="text"
            className={descriptionInputClasses.join(' ')}
            id="reservation-description"
            value={this.state.description.value}
            onChange={(e) => this.handleDescriptionChange(e.target.value)}
            placeholder={this.props.placeholder}
          />
          {!description.validationResult.valid &&
          description.validationResult.errorMessages &&
          description.validationResult.errorMessages.length ? (
            <div className="invalid-feedback">
              <ul>
                {description.validationResult.errorMessages.map((msg) => (
                  <li key={msg}>{msg}</li>
                ))}
              </ul>
            </div>
          ) : null}
        </div>
        <div className="form-check mt-2">
          <input
            tabIndex={5}
            id="description-remember-check"
            className="form-check-input"
            type="checkbox"
            checked={this.state.rememberDescription}
            onChange={(e) => this.handleRememberDescriptionChange(e.target.checked)}
          />
          <label className="form-check-label" htmlFor="description-remember-check">
            Muista kentän tieto tällä laitteella
            <FAT className="ml-1" id="description-remember-check-info" icon={faQuestionCircle}>
              Kenttään syötetty tieto tallennetaan selaimesi muistiin, jotta tieto voidaan
              esitäyttää seuraavalla kerralla.
            </FAT>
          </label>
        </div>
        <div className="form-check">
          <input
            tabIndex={6}
            id="description-check"
            className="form-check-input"
            type="checkbox"
            checked={this.state.descriptionPublic}
            onChange={(e) => this.handleDescriptionPublicChange(e.target.checked)}
          />
          <label className="form-check-label" htmlFor="description-check">
            Näytä kentän tieto muille tämän kalenterin käyttäjille
          </label>
        </div>
      </>
    );
  }
}
