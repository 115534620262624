import { faUserShield } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as joda from 'js-joda';
import { debounce, noop } from 'lodash';
import * as React from 'react';
import { getUA } from 'react-device-detect';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import {
  CreateReservation as CreateReservationModel,
  PricingMode,
  Reservation,
  ReservationDatetimeRange,
  ReservationMode,
  ReservationStatus,
  Resource,
  TimeRange,
  User,
} from '../../../models/models';
import { createToast } from '../../../notification';
import { setCalendarVisibleDateRange } from '../../../redux/calendar';
import {
  createReservationReFetchAllAndNavigateAsync,
  fetchReservationsAsync,
  setPreReservation,
} from '../../../redux/resource';
import { VarauksetState } from '../../../redux/store';
import * as paths from '../../../routerPaths';
import { ScrollToTopOnMount } from '../../../ScrollToTopOnMount';
import { getReservationTotalPrice, weekRangeForDate } from '../../../utils';
import { sufficientFundsBuilder, validate, ValidationResult } from '../../../validators';
import { ContentContainer } from '../../ContentContainer';
import { renderDemoNotification } from '../../ResourceDescription';
import ReservationConfirmModal from '../ReservationConfirmModal';
import AdminSelector, { ReservationCreationMode } from './AdminSelector';
import './CreateReservation.scss';
import DateAndTimeRangeSelect from './DateAndTimeRangeSelect';
import DescriptionField from './DescriptionField';
import EmailField from './EmailField';
import posthog from '../../../posthog';

function getTotalPrice(resource: Resource | undefined, dtr: ReservationDatetimeRange | undefined) {
  if (resource && dtr) {
    return getReservationTotalPrice(resource, dtr.timeRange);
  }
  return 0;
}

interface CreateReservationStateProps {
  preReservation: Reservation | null;
  otherReservations: Reservation[];
  resource?: Resource;
  initialDate?: joda.LocalDate;
  initialTimeRange?: TimeRange;
  user?: User;
  balance?: number;
}

interface CreateReservationDispatchProps {
  onReserve(
    reservation: CreateReservationModel,
    organizationSlug: string,
    resource: Resource
  ): void;
  onChangeVisibleDate(date: joda.LocalDate): void;
  onDeletePreReservation(): void;
}

interface CreateReservationRouterProps extends RouteComponentProps {
  organizationSlug: string;
  resourceId: string;
}

interface CreateReservationComponentProps {
  now?: joda.LocalDateTime;
}

interface CreateReservationProps
  extends CreateReservationStateProps,
    CreateReservationDispatchProps,
    CreateReservationRouterProps,
    CreateReservationComponentProps {}

interface CreateReservationFormState {
  dateAndTimeRange: ReservationDatetimeRange | undefined;
  dateAndTimeRangeValid: boolean;
  description: string;
  descriptionValid: boolean;
  descriptionPublic: boolean;
  email: string;
  emailValid: boolean;
}

interface CreateReservationState {
  form: CreateReservationFormState;
  honeypotContent: string;
  confirmModalOpen: boolean;
  creationMode: ReservationCreationMode;
}

export class CreateReservation extends React.Component<
  CreateReservationProps,
  CreateReservationState
> {
  constructor(props: CreateReservationProps) {
    super(props);

    if (props.preReservation && props.preReservation.status !== ReservationStatus.PreReservation) {
      throw Error('Unexpected reservation status.');
    }

    this.handleCreationModeChange = this.handleCreationModeChange.bind(this);
    this.handleDateAndTimeRangeChange = this.handleDateAndTimeRangeChange.bind(this);
    this.handleDateAndTimeValidityChange = this.handleDateAndTimeValidityChange.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.handleDescriptionPublicChange = this.handleDescriptionPublicChange.bind(this);
    this.handleDescriptionValidityChange = this.handleDescriptionValidityChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleEmailValidityChange = this.handleEmailValidityChange.bind(this);
    this.getUnconfirmedReservation = this.getUnconfirmedReservation.bind(this);
    this.openConfirmModal = this.openConfirmModal.bind(this);
    this.closeConfirmModal = this.closeConfirmModal.bind(this);
    this.handleOk = this.handleOk.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.debouncedChangeVisibleDateRange = debounce(this.changeVisibleDateRange, 200);

    this.state = {
      honeypotContent: '',
      confirmModalOpen: false,
      creationMode: ReservationCreationMode.Normal,
      // Real states will be updated by the sub components. The sub components always report
      // validity status after initialization.
      form: {
        dateAndTimeRange: undefined,
        dateAndTimeRangeValid: false,
        description: '',
        descriptionPublic: true,
        descriptionValid: false,
        email: '',
        emailValid: false,
      },
    };
  }

  // Function declaration required by typescript. Gets overwritten in constructor.
  debouncedChangeVisibleDateRange(date: joda.LocalDate) {
    noop();
  }

  changeVisibleDateRange(date: joda.LocalDate) {
    this.props.onChangeVisibleDate(date);
  }

  handleCreationModeChange(mode: ReservationCreationMode) {
    this.setState({ creationMode: mode });
  }

  handleDateAndTimeRangeChange(dt: ReservationDatetimeRange | undefined) {
    this.setState((prevState: CreateReservationState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        dateAndTimeRange: dt,
      },
    }));

    if (dt) {
      this.debouncedChangeVisibleDateRange(dt.date);
    }
  }

  handleDateAndTimeValidityChange(valid: boolean) {
    this.setState((prevState: CreateReservationState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        dateAndTimeRangeValid: valid,
        timeRangeValid: valid,
      },
    }));
  }

  handleDescriptionChange(value: string) {
    this.setState((prevState: CreateReservationState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        description: value,
      },
    }));
  }

  handleDescriptionValidityChange(valid: boolean) {
    this.setState((prevState: CreateReservationState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        descriptionValid: valid,
      },
    }));
  }

  handleDescriptionPublicChange(value: boolean) {
    this.setState((prevState: CreateReservationState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        descriptionPublic: value,
      },
    }));
  }

  handleEmailChange(value: string) {
    this.setState((prevState: CreateReservationState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        email: value,
      },
    }));
  }

  handleEmailValidityChange(valid: boolean) {
    this.setState((prevState: CreateReservationState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        emailValid: valid,
      },
    }));
  }

  componentDidUpdate(prevProps: CreateReservationProps, prevState: CreateReservationState) {
    const { form } = this.state;
    if (
      form.dateAndTimeRange &&
      prevState.form.dateAndTimeRange &&
      !form.dateAndTimeRange.date.equals(prevState.form.dateAndTimeRange.date)
    ) {
      if (form.dateAndTimeRangeValid) {
        this.debouncedChangeVisibleDateRange(form.dateAndTimeRange.date);
      }
    }
  }

  getUnconfirmedReservation() {
    const adminReservation = this.state.creationMode === ReservationCreationMode.Admin;
    const reservation: CreateReservationModel = {
      ...(this.state.form.dateAndTimeRange as ReservationDatetimeRange),
      resourceId: this.props.resourceId,
      description: this.state.form.description,
      descriptionPublic: this.state.form.descriptionPublic,
      email: this.state.form.email,
      userId: this.props.user ? this.props.user.id : undefined,
      adminReservation: adminReservation,
      totalPrice: adminReservation
        ? 0
        : getTotalPrice(this.props.resource, this.state.form.dateAndTimeRange),
    };
    return reservation;
  }

  openConfirmModal(event: React.SyntheticEvent<any>) {
    event.preventDefault();
    if (this.state.honeypotContent !== '') {
      posthog.capture('create reservation spam honeypot', {
        content: this.state.honeypotContent,
        ua: getUA,
      });
      createToast(botErrorMsg, 'warning');
    } else {
      this.setState({ confirmModalOpen: true });
    }
  }

  closeConfirmModal() {
    this.setState({ confirmModalOpen: false });
  }

  handleOk() {
    const reservation = this.getUnconfirmedReservation();
    if (this.props.preReservation) {
      this.props.onDeletePreReservation();
    }
    this.props.onReserve(reservation, this.props.organizationSlug, this.props.resource as Resource);
    this.closeConfirmModal();
  }

  handleCancel() {
    if (this.props.preReservation) {
      this.props.onDeletePreReservation();
    }
    if (this.props.resource) {
      this.props.history.push(
        paths.getResourceUrl(this.props.organizationSlug, this.props.resource.id)
      );
    }
  }

  renderUnitPrice() {
    if (this.props.resource && this.props.resource.price > 0) {
      const priceStr = `${this.props.resource.price.toFixed(2)} €`;
      let priceElement = undefined;
      if (this.props.resource.showHourPriceOnUi) {
        if (this.props.resource.pricingMode === PricingMode.ChargeByHour) {
          priceElement = (
            <>
              <strong>{priceStr}</strong> per alkava tunti.
            </>
          );
        } else if (this.props.resource.pricingMode === PricingMode.ChargeByHalfHour) {
          priceElement = (
            <>
              <strong>{priceStr}</strong> per tunti. Veloitetaan alkavan puolen tunnin mukaan.
            </>
          );
        }
      }
      const payingMode = this.state.creationMode !== ReservationCreationMode.Admin;
      return (
        <>
          <span>{priceElement}</span>
          <small className="form-text text-muted">
            {payingMode
              ? 'Varausmaksu veloitetaan Varaukset.fi-tililtäsi.'
              : 'Varausmaksua ei veloiteta ylläpitäjän varauksista.'}
          </small>
        </>
      );
    }
    return null;
  }

  renderTotalPrice() {
    const totalPrice = getTotalPrice(this.props.resource, this.state.form.dateAndTimeRange);
    if (totalPrice > 0 && this.state.creationMode === ReservationCreationMode.Normal) {
      return (
        <span className="badge badge-light" style={{ fontSize: '1rem' }}>{`${totalPrice.toFixed(
          2
        )} €`}</span>
      );
    }
    return null;
  }

  render() {
    const props = this.props;

    // If user navigates directly to this view or tries to open non-existing resource
    // redirect back to calendar view.
    if (!this.props.resource) {
      this.handleCancel();
    }

    const adminReservation = this.state.creationMode === ReservationCreationMode.Admin;
    const totalPrice = getTotalPrice(this.props.resource, this.state.form.dateAndTimeRange);
    let totalPriceValidationResult: ValidationResult = { valid: false };
    if (adminReservation) {
      totalPriceValidationResult = { valid: true };
    } else {
      totalPriceValidationResult = validate(totalPrice, [
        sufficientFundsBuilder(this.props.user ? this.props.user.balance : 0),
      ]);
    }

    const paidReservationUnauthenticatedUser = totalPrice > 0 && this.props.user === undefined;

    const formValid =
      this.state.form.dateAndTimeRangeValid &&
      this.state.form.descriptionValid &&
      this.state.form.emailValid &&
      totalPriceValidationResult.valid &&
      !paidReservationUnauthenticatedUser;

    let initialDate = this.props.initialDate;
    let initialTimeRange = this.props.initialTimeRange;
    if (this.props.preReservation) {
      initialDate = this.props.preReservation.date;
      initialTimeRange = this.props.preReservation.timeRange;
    }

    return (
      <ContentContainer>
        <ScrollToTopOnMount />
        <form onSubmit={this.openConfirmModal} noValidate={true}>
          <div className="row">
            <div className="col-lg-7">
              <h2>
                {`Varaa`} <span>{this.props.resource && this.props.resource.name}</span>
              </h2>
            </div>
            <div className="col-lg-5">
              <AdminSelector
                user={props.user}
                resource={props.resource}
                selected={this.state.creationMode}
                onChange={this.handleCreationModeChange}
              />
            </div>
          </div>
          {this.renderUnitPrice()}
          {this.props.resource && this.props.resource.reservationMode === ReservationMode.Demo ? (
            <div className="alert alert-info" role="alert">
              {renderDemoNotification()}
            </div>
          ) : null}

          {paidReservationUnauthenticatedUser ? (
            <div className="alert alert-warning" role="alert">
              Tämän varauksen tekeminen on maksullista.{' '}
              <Link to={paths.loginRegisterUrl}>Kirjaudu sisään</Link> ja tarvittaessa lataa
              tilillesi arvoa, jotta voit tehdä varauksen.
            </div>
          ) : null}

          {this.props.user &&
          !totalPriceValidationResult.valid &&
          totalPriceValidationResult.errorMessages &&
          totalPriceValidationResult.errorMessages.length ? (
            <div className="alert alert-warning" role="alert">
              {totalPriceValidationResult.errorMessages.join(' ')}
              <br />
              <Link to={paths.myAccountUrl}>Lataa arvoa Varaukset.fi-tilillesi.</Link>
            </div>
          ) : null}

          {/* Honeypot to detect spam bots */}
          <input
            id="reservation-hp"
            type="text"
            name="reservation-hp"
            size={25}
            value={this.state.honeypotContent}
            onChange={(e) => this.setState({ honeypotContent: e.target.value })}
          />
          <hr />
          <DateAndTimeRangeSelect
            adminReservation={adminReservation}
            rules={this.props.resource ? this.props.resource.rules : undefined}
            initialDate={initialDate}
            initialTimeRange={initialTimeRange}
            otherReservations={this.props.otherReservations}
            onDateAndTimeRangeChange={this.handleDateAndTimeRangeChange}
            onValidityChange={this.handleDateAndTimeValidityChange}
          />
          <hr />
          <DescriptionField
            label={this.props.resource ? this.props.resource.reservationDescriptionLabel : ''}
            placeholder={
              this.props.resource ? this.props.resource.reservationDescriptionPlaceholder : ''
            }
            resourceId={this.props.resource ? this.props.resourceId : undefined}
            onDescriptionChange={this.handleDescriptionChange}
            onValidityChange={this.handleDescriptionValidityChange}
            onDescriptionPublicChange={this.handleDescriptionPublicChange}
          />
          <EmailField
            user={props.user}
            onEmailChange={this.handleEmailChange}
            onValidityChange={this.handleEmailValidityChange}
            reservationMode={this.props.resource ? this.props.resource.reservationMode : undefined}
          />
          <hr />
          <div className="v-reservation-buttons">
            <button
              className="btn btn-secondary"
              type="button"
              onClick={this.handleCancel}
              tabIndex={7}
            >
              Peruuta
            </button>
            <button className="btn btn-primary" type="submit" disabled={!formValid} tabIndex={8}>
              {adminReservation && (
                <>
                  <FontAwesomeIcon icon={faUserShield} />{' '}
                </>
              )}
              Varaa {this.renderTotalPrice()}
            </button>
          </div>
        </form>
        {this.state.confirmModalOpen && (
          <ReservationConfirmModal
            now={this.props.now}
            reservation={this.getUnconfirmedReservation()}
            resource={this.props.resource as Resource}
            isOpen={this.state.confirmModalOpen}
            onCancel={this.closeConfirmModal}
            onConfirm={this.handleOk}
            totalPrice={totalPrice}
            reservationMode={this.state.creationMode}
          />
        )}
      </ContentContainer>
    );
  }
}

const mapStateToProps = (
  state: VarauksetState,
  ownProps: CreateReservationRouterProps & CreateReservationComponentProps
): CreateReservationStateProps => {
  let preReservation = state.resource.preReservation;

  return {
    preReservation,
    otherReservations:
      state.resource.reservations &&
      state.resource.reservations.filter((r) => !preReservation || preReservation.id !== r.id),
    resource:
      state.resource.resources &&
      state.resource.resources.find((r) => r.id === ownProps.resourceId),
    initialDate: state.resource.preReservation ? state.resource.preReservation.date : undefined,
    initialTimeRange: state.resource.preReservation
      ? state.resource.preReservation.timeRange
      : undefined,
    user: state.user.activeUser,
  };
};

const botErrorMsg =
  'Järjestelmä epäilee sinun olevan robotti. Varauksen teko estetty. ' +
  'Jos tämä on virhe, ota yhteyttä ylläpitoon.';

const mapDispatchToProps = (
  dispatch: ThunkDispatch<VarauksetState, undefined, AnyAction>,
  ownProps: CreateReservationRouterProps & CreateReservationComponentProps
): CreateReservationDispatchProps => ({
  onReserve: (
    reservation: CreateReservationModel,
    organizationSlug: string,
    resource: Resource
  ) => {
    const range = weekRangeForDate(reservation.date);
    dispatch(
      createReservationReFetchAllAndNavigateAsync(reservation, organizationSlug, resource, range)
    );
  },
  onChangeVisibleDate: (date: joda.LocalDate) => {
    const range = weekRangeForDate(date);
    dispatch(setCalendarVisibleDateRange(range));
    dispatch(fetchReservationsAsync(range, ownProps.resourceId));
  },
  onDeletePreReservation: () => dispatch(setPreReservation(null)),
});

const CreateReservationConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateReservation as any);

export default withRouter((props: RouteComponentProps<any> & CreateReservationComponentProps) => (
  <CreateReservationConnected
    {...props}
    organizationSlug={props.match.params.organizationSlug}
    resourceId={props.match.params.resourceId}
  />
));
