import { CaptureResult } from 'posthog-js';
import { devEnv, stagingEnv } from './utils';

function redactEmailFromString(text: string): string {
  const emailPattern = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/g;
  return text.replace(emailPattern, '***@***.***');
}

function sanitizeEmailFromElText(event: CaptureResult | null) {
  if (event && event.properties['$el_text']) {
    event.properties['$el_text'] = redactEmailFromString(event.properties['$el_text']);
  }
  return event;
}

function sanitizeEmailFromElementsChain(event: CaptureResult | null) {
  if (event && event.properties['$elements_chain']) {
    event.properties['$elements_chain'] = redactEmailFromString(
      event.properties['$elements_chain']
    );
  }
  return event;
}

// We could use class="ph-no-capture" to prevent capturing of events altogether from an element,
// but this way we can still capture an event but redact the email from it.
export function sanitizeEmailFromAutoCapture(event: CaptureResult | null) {
  return sanitizeEmailFromElText(sanitizeEmailFromElementsChain(event));
}

export function devLogEvent(event: CaptureResult | null) {
  if (devEnv() || stagingEnv()) {
    let env = devEnv() ? 'Dev' : 'Staging';
    console.log(`${env} PostHog event:`, event);
    return null; // Event == null means don't send the event to PostHog
  }
  return event;
}
