import * as Sentry from '@sentry/react';
import { applyMiddleware, compose, createStore, Middleware } from 'redux';
import { createLogger } from 'redux-logger';
import { persistCombineReducers, PersistConfig, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import { devEnv } from '../utils';
import history from './browserHistory';
import calendar, { CalendarState } from './calendar';
import confirm, { ConfirmState } from './confirm';
import myAccountTransactions, { MyAccountTransactionsState } from './myAccountTransactions';
import myReservations, { MyReservationsState } from './myReservations';
import organization, { OrganizationState } from './organization';
import paymentReport, { PaymentReportState } from './paymentReport';
import reservationDetails, { ReservationDetailsState } from './reservationDetails';
import resource, { ResourceState } from './resource';
import token, { TokenState } from './token';
import user, { UserState } from './user';
import wait, { WaitAction, WaitState } from './wait';
import posthog from '../posthog';

export interface VarauksetState {
  calendar: CalendarState;
  resource: ResourceState;
  organization: OrganizationState;
  wait: WaitState;
  user: UserState;
  token: TokenState;
  myReservations: MyReservationsState;
  myAccountTransactions: MyAccountTransactionsState;
  confirm: ConfirmState;
  reservationDetails: ReservationDetailsState;
  paymentReport: PaymentReportState;
}

export type GetState = () => VarauksetState;

const persistConfig: PersistConfig<VarauksetState> = {
  key: 'primary',
  storage,
  // Persist only token in tokenReducer
  whitelist: ['token'],
};

// TODO: Any type
const rootReducer = persistCombineReducers<VarauksetState, any>(persistConfig, {
  calendar,
  resource,
  organization,
  wait,
  user,
  token,
  myReservations,
  myAccountTransactions,
  confirm,
  reservationDetails,
  paymentReport,
});

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

function capturePageView() {
  // Note: prod/dev events are handled in posthog.ts
  posthog.capture('$pageview', {
    $current_url: window.location.href,
  });
}

capturePageView();

// Listen to changes to the current location, i.e., not called for current location. Hence, call once manually above.
// https://github.com/remix-run/history/blob/v4/docs/GettingStarted.md
history.listen((location, action) => capturePageView());

const middlewares: Middleware[] = [thunk];

if (devEnv()) {
  const reduxLoggerIgnoreActionTypes = [WaitAction.START_WAIT, WaitAction.STOP_WAIT];
  const loggerMiddleware = createLogger({
    predicate: (getState, action) => !reduxLoggerIgnoreActionTypes.find((i) => i === action.type),
    collapsed: true,
  });
  middlewares.push(loggerMiddleware);
}

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// TODO: Any types
const store = createStore<any, any, any, any>(
  rootReducer,
  composeEnhancers(applyMiddleware(...middlewares), sentryReduxEnhancer)
);

persistStore(store, null, () => {
  if (devEnv()) {
    console.log('Rehydration complete');
  }
});

export { store, history };
