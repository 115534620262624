import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import * as uuid from 'uuid';
import { LoginCredentials } from '../../models/models';
import { VarauksetState } from '../../redux/store';
import { loginAsync } from '../../redux/user';
import * as paths from '../../routerPaths';
import { ScrollToTopOnMount } from '../../ScrollToTopOnMount';
import { requiredString, validate, ValidationField, validEmailBuilder } from '../../validators';
import { ContentContainer } from '../ContentContainer';
import './Login.scss';

interface LoginRouterProps extends RouteComponentProps {
  prefilEmail?: string;
}

interface LoginComponentProps {
  redirectAfterLoginUrl?: string;
}

interface LoginStateProps {
  loggedIn: boolean;
}

interface LoginDispatchProps {
  onLogin(loginCredentials: LoginCredentials): void;
}

interface LoginProps
  extends LoginComponentProps,
    LoginStateProps,
    LoginDispatchProps,
    LoginRouterProps {}

interface LoginFormState {
  email: ValidationField<string>;
  password: ValidationField<string>;
}

interface LoginState {
  form: LoginFormState;
}

export class Login extends React.Component<LoginProps, LoginState> {
  constructor(props: LoginProps) {
    super(props);

    const initEmail = props.prefilEmail || '';
    this.state = {
      form: {
        email: {
          value: initEmail,
          validationResult: validate(initEmail, [validEmailBuilder(true)]),
          touched: false,
        },
        password: { value: '', validationResult: { valid: false }, touched: false },
      },
    };

    this.handleLoginSubmit = this.handleLoginSubmit.bind(this);
    this.handleRegisterNavigateClick = this.handleRegisterNavigateClick.bind(this);
    this.handleNewPasswordNavigateClick = this.handleNewPasswordNavigateClick.bind(this);
  }

  componentDidMount() {
    this.redirectIfNeeded(this.props);
  }

  componentWillReceiveProps(newProps: LoginProps) {
    this.redirectIfNeeded(newProps);
  }

  redirectIfNeeded(props: LoginProps) {
    if (props.loggedIn && props.redirectAfterLoginUrl) {
      props.history.push(props.redirectAfterLoginUrl);
    }
  }

  handleEmailChange(email: string) {
    this.setState((prevState: LoginState) => {
      return {
        form: {
          ...prevState.form,
          email: this.createEmailValidated(email),
        },
      };
    });
  }

  handlePasswordChange(password: string) {
    this.setState((prevState: LoginState) => {
      return {
        form: {
          ...prevState.form,
          password: this.createPasswordValidated(password),
        },
      };
    });
  }

  createEmailValidated(email: string) {
    return {
      value: email,
      touched: true,
      validationResult: validate(email, [validEmailBuilder(true)]),
    };
  }

  createPasswordValidated(password: string) {
    return {
      value: password,
      touched: true,
      validationResult: validate(password, [requiredString]),
    };
  }

  handleLoginSubmit(event: React.SyntheticEvent<any>) {
    event.preventDefault();
    this.props.onLogin({
      email: this.state.form.email.value,
      password: this.state.form.password.value,
    });
  }

  handleRegisterNavigateClick(event: React.SyntheticEvent<any>) {
    event.preventDefault();
    this.props.history.push(paths.registerUrl);
  }

  handleNewPasswordNavigateClick(event: React.SyntheticEvent<any>) {
    event.preventDefault();
    this.props.history.push(paths.newPasswordUrl);
  }

  render() {
    const form = this.state.form;
    const emailInputClasses = ['form-control'];
    const passwordInputClasses = ['form-control'];

    if (form.email.validationResult.valid) {
      emailInputClasses.push('is-valid');
    } else {
      emailInputClasses.push('is-invalid');
    }

    if (form.password.validationResult.valid) {
      passwordInputClasses.push('is-valid');
    } else {
      passwordInputClasses.push('is-invalid');
    }

    const formValid = form.email.validationResult.valid && form.password.validationResult.valid;

    let tabIndex = 1;
    return (
      <ContentContainer style={{ maxWidth: '500px', marginLeft: 'auto', marginRight: 'auto' }}>
        <ScrollToTopOnMount />
        <form id="v-login-form" onSubmit={this.handleLoginSubmit} noValidate={true}>
          <h2>Kirjaudu</h2>
          <p>
            <a
              href={paths.registerUrl}
              onClick={this.handleRegisterNavigateClick}
              tabIndex={tabIndex++}
            >
              Etkö ole vielä rekisteröitynyt? Rekisteröidy.
            </a>
          </p>
          <p>
            <a
              href={paths.registerUrl}
              onClick={this.handleNewPasswordNavigateClick}
              tabIndex={tabIndex++}
            >
              Unohtuiko salasana? Vaihda salasana.
            </a>
          </p>

          <hr />

          <div className="form-group">
            <label htmlFor="login-email">Email</label>
            <input
              autoFocus={true}
              tabIndex={tabIndex++}
              type="email"
              className={emailInputClasses.join(' ')}
              id="login-email"
              value={this.state.form.email.value}
              onChange={(e) => this.handleEmailChange(e.target.value)}
              placeholder="sähkö@posti.fi"
            />
            {form.email.touched &&
            !form.email.validationResult.valid &&
            form.email.validationResult.errorMessages &&
            form.email.validationResult.errorMessages.length ? (
              <div className="invalid-feedback">
                <ul>
                  {form.email.validationResult.errorMessages.map((msg) => (
                    <li key={uuid.v4()}>{msg}</li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>

          <div className="form-group">
            <label htmlFor="login-password">Salasana</label>
            <input
              tabIndex={tabIndex++}
              type="password"
              className={passwordInputClasses.join(' ')}
              id="login-password"
              value={this.state.form.password.value}
              onChange={(e) => this.handlePasswordChange(e.target.value)}
            />
            {form.password.touched &&
            !form.password.validationResult.valid &&
            form.password.validationResult.errorMessages &&
            form.password.validationResult.errorMessages.length ? (
              <div className="invalid-feedback">
                <ul>
                  {form.password.validationResult.errorMessages.map((msg) => (
                    <li key={uuid.v4()}>{msg}</li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>

          <button
            className="btn btn-primary btn-lg"
            type="submit"
            disabled={!formValid}
            tabIndex={tabIndex++}
          >
            Kirjaudu sisään
          </button>
          <p className="mt-2 form-text text-muted">
            Kirjautumisesi muistetaan tällä laitteella, kunnes kirjaudut ulos.
          </p>
        </form>
      </ContentContainer>
    );
  }
}

const mapStateToProps = (state: VarauksetState): LoginStateProps => ({
  loggedIn: !!state.token.token,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<VarauksetState, undefined, AnyAction>
): LoginDispatchProps => ({
  onLogin: (loginCredentials: LoginCredentials) => dispatch(loginAsync(loginCredentials)),
});

const LoginConnected = connect(mapStateToProps, mapDispatchToProps)(Login);

export default withRouter((props: RouteComponentProps<any> & LoginComponentProps) => {
  const params = new URLSearchParams(props.location.search);
  return <LoginConnected {...props} prefilEmail={params.get('email') || undefined} />;
});
