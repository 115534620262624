import * as Sentry from '@sentry/react';
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import FileSaver from 'file-saver';
import * as uuid from 'uuid';
import { StripePaymentIntentDto } from './models/dtos';
import {
  dtosToAccountTransactions,
  dtosToMyReservations,
  dtosToOrganizations,
  dtosToReservations,
  dtosToResources,
  dtoToOrganization,
  dtoToReservation,
  dtoToResource,
  dtoToUser,
  reservationToDto,
  resourceToDto,
} from './models/modelConverters';
import {
  AccountTransaction,
  CreateReservation,
  DateRange,
  EditReservation,
  EditResource,
  LoginCredentials,
  MyReservations,
  Organization,
  RegisterData,
  Reservation,
  ReservationMode,
  ReservationStatus,
  Resource,
  SetPasswordData,
  StripePaymentIntent,
  User,
} from './models/models';
import { createToast, IdempotencyKeys } from './notification';
import { getStore } from './redux/storeRegistry';
import { receiveToken } from './redux/token';
import { clearLocalUser } from './redux/userSharedActions';
import { getFilenameFromHeaders, isDateBetween, SIMPLE_ISO_DATE_FORMATTER } from './utils';

export interface APIResponse {
  success: boolean;
  errorMessages?: string[];
}

export interface APIDataResponse<T> extends APIResponse {
  data?: T;
}

const requestTimeoutMS: number = 30 * 1000;
const backendUrl: string = process.env.REACT_APP_API_URL as string;

function isErrorStatus(status: number) {
  if (status >= 200 && status < 300) {
    return false;
  }
  if (status >= 400 && status < 405) {
    return false;
  }
  return true;
}

const defaultHeaders: any = {
  'Cache-Control': 'no-cache, no-store, max-age=0, must-revalidate',
  Pragma: 'no-cache',
};

const defaultJsonHeaders: any = {
  ...defaultHeaders,
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

function getClient(headers: any, config: any = {}): AxiosInstance {
  const store = getStore();
  const token = store.getState().token.token;
  if (token) {
    headers = { ...headers, Authorization: `Token ${token}` };
  }

  // return normally on status codes 100...499, throw on 500..
  // default would also throw on 400 errors. Validation errors are a bit
  // easier to handle from normal return payload than in catch-clause.
  const validateStatus = (status: number) => status < 500;
  const axiosInstance = axios.create({
    baseURL: backendUrl,
    headers: headers,
    timeout: requestTimeoutMS,
    validateStatus: validateStatus,
    ...config,
  });

  function successInterceptor(response: AxiosResponse<any>) {
    if (isErrorStatus(response.status)) {
      console.warn(response);
      Sentry.captureMessage(JSON.stringify(response));
      createToast('Jokin meni pieleen.', 'error', IdempotencyKeys.GeneralApiError);
    }
    if (token && response.status === 401) {
      // Update user status to logged out, if server response is 401, although we have token in
      // state.
      store.dispatch(receiveToken(null));
      store.dispatch(clearLocalUser());
    }
    return response;
  }

  function rejectedInterceptor(error?: any) {
    console.warn(error);
    if (error.message === 'Network Error') {
      createToast('Virhe verkkoyhteydessä', 'error', IdempotencyKeys.NetworkApiError);
    } else {
      createToast('Jokin meni pieleen.', 'error', IdempotencyKeys.GeneralApiError);
    }
    return error;
  }

  axiosInstance.interceptors.response.use(successInterceptor, rejectedInterceptor);

  return axiosInstance;
}

function jsonClient(): AxiosInstance {
  return getClient(defaultJsonHeaders);
}

function fileClient(): AxiosInstance {
  return getClient(defaultHeaders, { responseType: 'arraybuffer' });
}

const demoReservations: Reservation[] = [];

export async function getReservations(
  range: DateRange,
  resourceId: string
): Promise<Reservation[]> {
  try {
    const startStr = range.start.format(SIMPLE_ISO_DATE_FORMATTER);
    const endStr = range.end.format(SIMPLE_ISO_DATE_FORMATTER);
    const response = await jsonClient().get(
      `/api/resources/${resourceId}/reservations/?start_date=${startStr}&end_date=${endStr}`
    );
    let reservations = dtosToReservations(response.data);

    // Demo reservations are always added to result without checking if this resource has demo mode.
    // This is safe, because demo mode is checked when reservations are added.
    reservations = reservations.concat(
      // TODO: Repeating demo reservations?
      demoReservations
        .filter((r) => r.resourceId === resourceId)
        .filter((r) => isDateBetween(r.date, range))
    );

    return reservations;
  } catch (e) {
    console.warn('Reservations fetch failed.' + e);
    return [];
  }
}

export async function getReservation(reservationId: string): Promise<Reservation | undefined> {
  try {
    const response = await jsonClient().get(`/api/reservations/${reservationId}/`);
    let reservation: Reservation | undefined;
    if (response.status === 404) {
      reservation = demoReservations.find((r) => r.id === reservationId);
    } else {
      reservation = dtoToReservation(response.data);
    }
    return reservation;
  } catch (e) {
    console.warn('Reservation fetch failed.' + e);
    return undefined;
  }
}

export function serverErrorMsgsToString(err: any) {
  if (typeof err === 'string') {
    return err;
  }

  const errorMessages: string[] = [];
  for (let key in err) {
    if (err.hasOwnProperty(key)) {
      let value: any = err[key];
      if (typeof value === 'string') {
        errorMessages.push(value);
      } else {
        for (let msg of value) {
          errorMessages.push(msg);
        }
      }
    }
  }
  return errorMessages.join(' | ');
}

export async function createReservation(
  reservation: CreateReservation,
  resource: Resource
): Promise<APIResponse> {
  if (resource.reservationMode === ReservationMode.Demo) {
    // Remove some props from reservation to be able to spread it below without getting
    // unspecified props.
    const { email, totalPrice, ...base } = reservation;
    const newReservation: Reservation = {
      ...base,
      id: uuid.v4(),
      status: ReservationStatus.Confirmed,
      totalPricePaid: reservation.totalPrice,
    };
    demoReservations.push(newReservation);
    return { success: true };
  }

  try {
    const data = reservationToDto(reservation);
    const response = await jsonClient().post(`/api/reservations/`, data);
    if (response.status === 201) {
      return { success: true };
    } else if (response.status === 400) {
      return {
        success: false,
        errorMessages: [serverErrorMsgsToString(response.data)],
      };
    }
    throw Error('Response status: ' + response.status.toString());
  } catch (e) {
    console.warn('Reservation creation failed.' + e);
    // TODO: Any type
    return { success: false, errorMessages: [(e as any).toString()] };
  }
}

export async function deleteReservation(
  reservationId: string,
  resource: Resource
): Promise<APIResponse> {
  if (resource.reservationMode === ReservationMode.Demo) {
    const index = demoReservations.findIndex((dr) => dr.id === reservationId);
    if (index !== -1) {
      demoReservations.splice(index, 1);
    }
    return { success: true };
  }

  try {
    const response = await jsonClient().delete(`/api/reservations/${reservationId}/`);
    const status = response.status;
    if (status === 204 || status === 200) {
      return { success: true };
    } else if (status === 400 || status === 401) {
      return { success: false, errorMessages: [serverErrorMsgsToString(response.data)] };
    }
    throw Error('Response status: ' + response.status.toString());
  } catch (e) {
    console.warn('Reservation delete failed.' + e);
    return { success: false, errorMessages: [(e as any).toString()] };
  }
}

export async function editReservation(reservation: EditReservation): Promise<APIResponse> {
  try {
    const data = {
      repeat_last_date: reservation.repeatLastDate,
    };
    const response = await jsonClient().patch(`/api/reservations/${reservation.id}/`, data);
    const status = response.status;
    if (status === 200) {
      return { success: true };
    } else if (status === 400) {
      return { success: false, errorMessages: [serverErrorMsgsToString(response.data)] };
    }
    throw Error('Response status: ' + response.status.toString());
  } catch (e) {
    console.warn('Reservation patch failed.' + e);
    return { success: false, errorMessages: [(e as any).toString()] };
  }
}

export async function getResource(resourceId: string) {
  try {
    const response = await jsonClient().get(`/api/resources/${resourceId}/`);
    return dtoToResource(response.data);
  } catch (e) {
    console.warn('Resource fetch failed.' + e);
    return undefined;
  }
}

export async function getResources(organizationId: string) {
  try {
    const response = await jsonClient().get(`/api/organizations/${organizationId}/resources/`);
    return dtosToResources(response.data);
  } catch (e) {
    console.warn('Resources fetch failed.' + e);
    return [];
  }
}

export async function editResource(resource: EditResource): Promise<APIResponse> {
  try {
    const data = resourceToDto(resource);
    const response = await jsonClient().put(`/api/resources/${resource.id}/`, data);
    const status = response.status;
    if (status === 200) {
      return { success: true };
    } else if (status === 400) {
      return { success: false, errorMessages: [serverErrorMsgsToString(response.data)] };
    }
    throw Error('Response status: ' + response.status.toString());
  } catch (e) {
    console.warn('Resource put failed.' + e);
    return { success: false, errorMessages: [(e as any).toString()] };
  }
}

export async function getOrganizationBySlug(slug: string) {
  try {
    const response = await jsonClient().get(`/api/organizations/slug/${slug}/`);
    return dtoToOrganization(response.data);
  } catch (e) {
    console.warn('Organization fetch by slug failed. ' + e);
    return undefined;
  }
}

export async function getOrganizations() {
  try {
    const response = await jsonClient().get('/api/organizations/');
    return dtosToOrganizations(response.data);
  } catch (e) {
    console.warn('Organizations fetch failed. ' + e);
    return [];
  }
}

export async function getOrganizationsFull() {
  try {
    const response = await jsonClient().get('/api/organizations-full/');
    return dtosToOrganizations(response.data);
  } catch (e) {
    console.warn('Organizations full list fetch failed. ' + e);
    return [];
  }
}

export async function register(registerData: RegisterData): Promise<APIDataResponse<string>> {
  try {
    const data = {
      email: registerData.email,
      password1: registerData.password1,
      password2: registerData.password2,
    };
    const response = await jsonClient().post('/rest-auth/registration/', data);
    if (response.status === 201) {
      return { success: true };
    }
    throw Error(serverErrorMsgsToString(response.data));
  } catch (e) {
    console.warn('Registration failed. ' + e);
    return { success: false, errorMessages: [(e as any).toString()] };
  }
}

export async function login(loginCredentials: LoginCredentials): Promise<APIDataResponse<string>> {
  try {
    const data = {
      email: loginCredentials.email,
      password: loginCredentials.password,
    };
    const response = await jsonClient().post('/rest-auth/login/', data);
    if (response.status === 200) {
      return { success: true, data: response.data.key };
    } else if (response.status === 400) {
      return {
        success: false,
        errorMessages: ['Sähköpostiosoite on väärä, sitä ei ole vahvistettu tai väärä salasana.'],
      };
    }
    throw Error(serverErrorMsgsToString(response.data));
  } catch (e) {
    console.warn('Login failed. ' + e);
    return { success: false, errorMessages: [(e as any).toString()] };
  }
}

export async function getUser(): Promise<APIDataResponse<User>> {
  try {
    const response = await jsonClient().get('/rest-auth/user/');
    const user = dtoToUser(response.data);

    return { success: true, data: user };
  } catch (e) {
    console.warn('User fetch failed. ' + e);
    return { success: false };
  }
}

export async function logout(): Promise<APIResponse> {
  try {
    const response = await jsonClient().post('/rest-auth/logout/');
    if (response.status === 200) {
      return { success: true };
    }
    throw Error('Response status: ' + response.status.toString());
  } catch (e) {
    console.warn('Logout failed. ' + e);
    return { success: false, errorMessages: [(e as any).toString()] };
  }
}

export async function getMyReservations(): Promise<APIDataResponse<MyReservations>> {
  try {
    const response = await jsonClient().get('/api/my-reservations/');
    // Demo reservations are not included in myReservations.
    // That would require fetching orgs and resources of demo reservations separately.
    const myReservations = dtosToMyReservations(response.data);
    return { success: true, data: myReservations };
  } catch (e) {
    console.warn('My reservations fetch failed. ' + e);
    return { success: false };
  }
}

export async function getMyAccountTransactions(
  year: number
): Promise<APIDataResponse<AccountTransaction[]>> {
  try {
    const response = await jsonClient().get(`/api/my-account-transactions/${year}/`);
    const myAccountTransactions = dtosToAccountTransactions(response.data);
    return { success: true, data: myAccountTransactions };
  } catch (e) {
    console.warn('My account transactions getch failed. ' + e);
    return { success: false };
  }
}

export async function confirmReservation(confirmationToken: string) {
  try {
    const data = { reservation_uuid: confirmationToken };
    const response = await jsonClient().post('/confirm-reservation/', data);
    return response.status === 200;
  } catch (e) {
    console.warn('Reservation confirmation failed. ' + e);
    return false;
  }
}

export async function confirmEmail(confirmationToken: string) {
  try {
    const data = { key: confirmationToken };
    const response = await jsonClient().post('/rest-auth/registration/verify-email/', data);
    return response.status === 200;
  } catch (e) {
    console.warn('Email confirmation failed. ' + e);
    return false;
  }
}

export async function requestPasswordResetLink(email: string) {
  try {
    const data = { email };
    await jsonClient().post('/rest-auth/password/reset/', data);
    return true;
  } catch (e) {
    console.warn('Email reset link request failed. ' + e);
    return false;
  }
}

export async function setPassword(setPasswordData: SetPasswordData): Promise<APIResponse> {
  try {
    const data = {
      uid: setPasswordData.uid,
      token: setPasswordData.resetToken,
      new_password1: setPasswordData.newPassword,
      new_password2: setPasswordData.newPassword,
    };
    const response = await jsonClient().post('/rest-auth/password/reset/confirm/', data);
    if (response.status === 200) {
      return { success: true };
    } else if (response.status === 400) {
      return {
        success: false,
        errorMessages: [serverErrorMsgsToString(response.data)],
      };
    }
    throw Error('Response status: ' + response.status);
  } catch (e) {
    console.warn('Password resert failed. ' + e);
    return { success: false, errorMessages: [(e as any).toString()] };
  }
}

export async function setMyOrganizations(organizations: Organization[]): Promise<APIResponse> {
  try {
    const data = organizations.map((o) => parseInt(o.id, 10));
    const response = await jsonClient().put('/api/my-bookmarked-organizations/', data);
    if (response.status === 200) {
      return { success: true };
    }
    throw Error('Response status: ' + response.status);
  } catch (e) {
    console.warn('Set my organizations failed. ' + e);
    return { success: false };
  }
}

export async function downloadPaymentsReport(range: DateRange, organizationId: string) {
  const startStr = range.start.format(SIMPLE_ISO_DATE_FORMATTER);
  const endStr = range.end.format(SIMPLE_ISO_DATE_FORMATTER);

  const response = await fileClient().get(
    `/export/organizations/${organizationId}/payments-report/` +
      `?start_date=${startStr}&end_date=${endStr}`
  );

  const filename = getFilenameFromHeaders(response.headers);
  const blob = new Blob([response.data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  FileSaver.saveAs(blob, filename);
}

export async function downloadReservationsReport(range: DateRange, organizationId: string) {
  const startStr = range.start.format(SIMPLE_ISO_DATE_FORMATTER);
  const endStr = range.end.format(SIMPLE_ISO_DATE_FORMATTER);

  const response = await fileClient().get(
    `/export/organizations/${organizationId}/reservations-report/` +
      `?start_date=${startStr}&end_date=${endStr}`
  );

  const filename = getFilenameFromHeaders(response.headers);
  const blob = new Blob([response.data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  FileSaver.saveAs(blob, filename);
}

export async function createStripePaymentIntent(
  amount: number,
  transactionFee: number
): Promise<APIDataResponse<StripePaymentIntent>> {
  try {
    const data: StripePaymentIntentDto = {
      amount,
      transaction_fee: transactionFee,
    };

    const response = await jsonClient().post('/api/my-account-payment-intent/', data);
    if (response.status === 201) {
      return {
        success: true,
        data: { id: response.data.id, clientSecret: response.data.client_secret },
      };
    } else if (response.status === 400) {
      return { success: false, errorMessages: [serverErrorMsgsToString(response.data)] };
    }
    throw Error('Response status: ' + response.status);
  } catch (e) {
    console.warn('Payment intent creation failed. ' + e);
    return { success: false, errorMessages: [(e as any).toString()] };
  }
}

export async function updateStripePaymentIntent(
  paymentIntentId: string,
  amount: number,
  transactionFee: number
): Promise<APIResponse> {
  try {
    const data: StripePaymentIntentDto = {
      amount,
      transaction_fee: transactionFee,
    };

    const response = await jsonClient().put(
      `/api/my-account-payment-intent/${paymentIntentId}/`,
      data
    );
    if (response.status === 200) {
      return { success: true };
    } else if (response.status === 400) {
      return { success: false, errorMessages: [serverErrorMsgsToString(response.data)] };
    }
    throw Error('Response status: ' + response.status);
  } catch (e) {
    console.warn('Payment intent update failed. ' + e);
    return { success: false, errorMessages: [(e as any).toString()] };
  }
}
