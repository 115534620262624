import posthog from 'posthog-js';
import { devLogEvent, sanitizeEmailFromAutoCapture } from './posthogHelpers';

const posthogApiKey = process.env.REACT_APP_POSTHOG_API_KEY || '';
const posthogHost = process.env.REACT_APP_POSTHOG_HOST || '';

// This code runs once when the module is first imported
posthog.init(posthogApiKey, {
  api_host: posthogHost,
  persistence: 'memory',
  disable_persistence: true,
  capture_pageview: false,
  // IP sanitization is set on in PostHog Dashboard settings "Discard client IP data"
  before_send: [sanitizeEmailFromAutoCapture, devLogEvent],
});

export default posthog;
