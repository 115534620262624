import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import moment from 'moment';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import App from './App';
import './index.scss';
import { history, store } from './redux/store';
import { registerStore } from './redux/storeRegistry';
import reportWebVitals from './reportWebVitals';

// PostHog is initialized in store.ts imported by this file

function sanitizeIp(event: Sentry.Event): Sentry.Event {
  // Remove IP address
  if (event.request && event.request.headers) {
    delete event.request.headers['client-ip'];
  }

  // Also remove from user context if present
  if (event.user && event.user.ip_address) {
    delete event.user.ip_address;
  }

  return event;
}

const sentryDsn = process.env.REACT_APP_SENTRY_DSN;
Sentry.init({
  dsn: sentryDsn,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0, // For transaction tracing. Transaction events != error events. Transaction events are for performance monitoring.
  beforeSend: (event) => {
    event = sanitizeIp(event);
    return event;
  },
});

registerStore(store);

// Set global locale. Moment is used for some toString and humanize purposes.
moment.locale('fi');

const stripeApiKey: string = process.env.REACT_APP_STRIPE_KEY || 'no-key';
const stripePromise = loadStripe(stripeApiKey, { locale: 'fi' });

ReactDOM.render(
  <Elements stripe={stripePromise}>
    <Provider store={store}>
      <Router history={history}>
        <App />
      </Router>
    </Provider>
  </Elements>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
